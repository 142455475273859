import React from 'react'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { OrangeIcon } from 'src/components/UI/MarkdownIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'

import carouselData from './data.json'

import { SectionWrapper } from './style'

type CarouselData = {
  icon: string;
  description: string;
  icon2: string;
  description2: string;
}

type modalProps = {
  setIsOpen: Function;
}

type CarouselDot = {
  index: string;
  onClick: Function;
  active: string;
}

const ComOsBeneficios = ({ setIsOpen }: modalProps) => {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  const CustomDot = ({ index, onClick, active }: CarouselDot) => {
    return (
      <li
        className={active
          ? 'text-white bg-grayscale--500 mx-2 custom-dots fs-12 fw-700'
          : 'text-grayscale--500 bg-white mx-2 custom-dots fs-12 fw-700'}
        onClick={() => onClick()}
      >
        {index + 1}
      </li>
    )
  }

  return (
    <SectionWrapper>
      <div className='container'>
        <div className='row justify-content-between justify-content-md-center'>
          <div className='col-12 d-flex flex-column justify-content-center text-md-center'>
            <h2 className='fs-24 fs-md-24 fs-lg-40 lh-lg-50 lh-md-40 lh-30 mb-md-4 text-grayscale--500'>
              Com os benefícios que você precisa
            </h2>
          </div>
          <div className='col-12 col-md-6 col-lg-12 d-flex flex-column justify-content-center text-lg-center'>
            <p className={`fs-14 fs-lg-18 lh-lg-22 lh-20 fw-400 mb-lg-4 ${width <= 768 ? 'text-grayscale--400' : 'text-grayscale--500'}`}>Depois de ver as opções de plano, escolha aquele que tem os benefícios <span className='d-lg-block'>que fazem sentido pra você. Entre eles, você pode ter:</span></p>
          </div>
          {
            width > 768 &&
            (
              <div className='bg-white rounded-row row mt-5'>
                <div className='col-4 d-flex flex-column align-items-center py-5 px-5'>
                  <OrangeIcon icon='star' size='XL' color='#FF7A00' />
                  <h4 className='fs-16 text-grayscale--500 mt-4 text-center'>A mais ampla rede credenciada</h4>
                </div>
                <div className='col-4 d-flex flex-column align-items-center py-5 px-5'>
                  <OrangeIcon icon='users' size='XL' color='#FF7A00' />
                  <h4 className='fs-16 text-grayscale--500 mt-4 text-center'>Com ou sem coparticipação</h4>
                </div>
                <div className='col-4 d-flex flex-column align-items-center py-5 px-5'>
                  <OrangeIcon icon='savings' size='XL' color='#FF7A00' />
                  <h4 className='fs-16 text-grayscale--500 mt-4 text-center'>Com reembolso</h4>
                </div>
                <hr className='my-0' />
                <div className='col-4 d-flex flex-column align-items-center py-5 px-5'>
                  <OrangeIcon icon='medical-insurance' size='XL' color='#FF7A00' />
                  <h4 className='fs-16 text-grayscale--500 mt-4 text-center'>Telemedicina</h4>
                </div>
                <div className='col-4 d-flex flex-column align-items-center py-5 px-5'>
                  <OrangeIcon icon='bed' size='XL' color='#FF7A00' />
                  <h4 className='fs-16 text-grayscale--500 mt-4 text-center'>Internação em apartamento ou enfermaria</h4>
                </div>
                <div className='col-4 d-flex flex-column align-items-center py-5 px-5'>
                  <OrangeIcon icon='attention' size='XL' color='#FF7A00' />
                  <h4 className='fs-16 text-grayscale--500 mt-4 text-center'>E vários benefícios adicionais</h4>
                </div>
              </div>
            )
          }
          {
            width <= 768 &&
            (
              <div className='col-12'>
                <DefaultCarousel
                  sm={{ items: 1 }}
                  md={{ items: 1 }}
                  autoPlay={true}
                  infinite={true}
                  notShowArrows={true}
                  customDots={CustomDot}
                  transitionDuration={500}
                  autoPlaySpeed={4000}
                >
                  {
                    carouselData.map((item: CarouselData, index: number) => (
                      <div
                        key={index}
                        className='py-4 px-3 bg-white rounded-5 d-flex flex-column justify-content-center align-items-center carousel-div'
                      >
                        <OrangeIcon icon={item.icon} size='LD' color='#FF7A00' />
                        <h4 className='fs-16 text-grayscale--500 mt-4 text-center'>{item.description}</h4>
                        <hr />
                        <OrangeIcon icon={item.icon2} size='LD' color='#FF7A00' />
                        <h4 className='fs-16 text-grayscale--500 mt-4 text-center'>{item.description2}</h4>
                      </div>
                    ))
                  }
                </DefaultCarousel>
              </div>
            )
          }
          <div className='col-12 d-flex justify-content-center'>
            <button
              className='d-none d-md-block btn--lg btn btn-orange--extra text-white rounded-3 fs-14 fw-600 mb-lg-0 text-none mt-md-4'
              onClick={ () => {
                setIsOpen(true)
                sendDatalayerEvent({
                  section: 'dobra_4',
                  section_name: 'Com os benefícios que você precisa',
                  element_action: 'click button',
                  element_name: 'Simular no app',
                })
              }}
            >
              Simular no app
            </button>
            <a
              className='d-block d-md-none btn--lg btn btn-orange--extra text-white rounded-2 pt-3 fs-14 fw-600 mb-lg-0 text-none mt-md-4'
              href='https://intergo.app/0edd3865'
              onClick={ () => {
                sendDatalayerEvent({
                  section: 'dobra_4',
                  section_name: 'Com os benefícios que você precisa',
                  element_action: 'click button',
                  element_name: 'Simular no app',
                  redirect_url: 'https://intergo.app/0edd3865',
                })
              }}
            >
              Simular no app
            </a>
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default ComOsBeneficios
